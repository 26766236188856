.dashboard {
  padding: 20px;
  background: linear-gradient(to right, #ffecd2, #fcb69f);
}

.admin-panel {
  background-color: #ffe3e3;
  padding: 10px;
  border-radius: 5px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.card h3 {
  color: #333333;
  font-family: "Arial", sans-serif;
  margin-bottom: 10px;
}

.icon {
  color: #f77a52;
  margin-right: 10px;
}

.container-fluid {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the top */
}

.col-md-3,
.col-md-9 {
  padding: 10px;
}

.upload-button {
  background-color: #2d30ca; /* Dark background color */
  color: #ffffff; /* White text color */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: inline-block; /* Ensures correct display */
  text-align: center; /* Centers text inside the button */
}

.upload-button:hover {
  background-color: #555555; /* Slightly lighter shade on hover */
}

@media (max-width: 768px) {
  .col-md-3,
  .col-md-9 {
    padding: 5px;
  }
  .card {
    width: 100%;
  }
}
