/* In your CSS file, e.g., styles/CreateBlog.css */

/* Import fonts from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Arial&family=Comic+Sans+MS&family=Courier+New&family=Georgia&family=Helvetica&family=Lucida&family=Times+New+Roman&family=Verdana&family=Tahoma&family=Trebuchet+MS&family=Gill+Sans&family=Impact&family=Arial+Black&family=Palatino&family=Garamond&family=Bookman&family=Comic+Sans+MS&family=Candara&family=Consolas&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

/* Define font-family classes */
.ql-font-arial {
  font-family: "Arial", sans-serif;
}
.ql-font-comic-sans {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new {
  font-family: "Courier New", monospace;
}
.ql-font-georgia {
  font-family: "Georgia", serif;
}
.ql-font-helvetica {
  font-family: "Helvetica", sans-serif;
}
.ql-font-lucida {
  font-family: "Lucida", sans-serif;
}
.ql-font-times-new-roman {
  font-family: "Times New Roman", serif;
}
.ql-font-verdana {
  font-family: "Verdana", sans-serif;
}
.ql-font-tahoma {
  font-family: "Tahoma", sans-serif;
}
.ql-font-trebuchet-ms {
  font-family: "Trebuchet MS", sans-serif;
}
.ql-font-gill-sans {
  font-family: "Gill Sans", sans-serif;
}
.ql-font-impact {
  font-family: "Impact", sans-serif;
}
.ql-font-arial-black {
  font-family: "Arial Black", sans-serif;
}
.ql-font-palatino {
  font-family: "Palatino", serif;
}
.ql-font-garamond {
  font-family: "Garamond", serif;
}
.ql-font-bookman {
  font-family: "Bookman", serif;
}
.ql-font-comic-sans-ms {
  font-family: "Comic Sans MS", cursive;
}
.ql-font-candara {
  font-family: "Candara", sans-serif;
}
.ql-font-consolas {
  font-family: "Consolas", monospace;
}
.ql-font-playfair-display {
  font-family: "Playfair Display", serif;
}

/* CreateBlog Specific Styles */
.dashboard {
  padding: 20px;
  background: linear-gradient(to right, #ffecd2, #fcb69f);
}

.admin-menu {
  background-color: #ffe3e3;
  padding: 10px;
  border-radius: 5px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.container-fluid {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the top */
  flex-wrap: wrap;
  margin-top: 60px; /* To account for fixed admin menu */
}

.col-md-3,
.col-md-9 {
  padding: 10px;
}

.create-blog {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
}

.create-blog h1 {
  color: #333333;
  font-family: "Arial", sans-serif;
  margin-bottom: 20px;
}

.create-blog .form-group {
  margin-bottom: 15px;
}

.create-blog .form-control,
.create-blog .btn {
  margin-bottom: 15px;
}

.create-blog .btn-primary {
  background-color: #f77a52;
  border-color: #f77a52;
}

.create-blog .btn-primary:hover {
  background-color: #f55a2c;
  border-color: #f55a2c;
}

@media (max-width: 768px) {
  .col-md-3,
  .col-md-9 {
    padding: 5px;
  }
  .create-blog {
    width: 100%; /* Full width on smaller screens */
  }
}

/* ManageBlog Specific Styles */
.dashboard {
  padding: 20px;
  background: linear-gradient(to right, #ffecd2, #fcb69f);
}

.admin-menu {
  background-color: #ffe3e3;
  padding: 10px;
  border-radius: 5px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.container-fluid {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the top */
  flex-wrap: wrap;
  margin-top: 60px; /* To account for fixed admin menu */
}

.col-md-3,
.col-md-9 {
  padding: 10px;
}

.blog-box {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
}

.blog-box h2 {
  color: #333333;
  font-family: "Arial", sans-serif;
  margin-bottom: 10px;
}

.blog-box .blog-content {
  margin-bottom: 15px;
}

.blog-box .btn {
  margin-right: 10px;
}

.blog-box .btn-primary {
  background-color: #f77a52;
  border-color: #f77a52;
}

.blog-box .btn-primary:hover {
  background-color: #f55a2c;
  border-color: #f55a2c;
}

.blog-box .btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
}

.blog-box .btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
}

.blog-box .btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.blog-box .btn-secondary:hover {
  background-color: #5a6268;
  border-color: #545b62;
}

@media (max-width: 768px) {
  .col-md-3,
  .col-md-9 {
    padding: 5px;
  }
  .blog-box {
    width: 100%; /* Full width on smaller screens */
  }
}

/* Add font faces for custom fonts */
.ql-font-arial {
  font-family: "Arial", sans-serif;
}
.ql-font-comic-sans {
  font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new {
  font-family: "Courier New", Courier, monospace;
}
.ql-font-georgia {
  font-family: "Georgia", serif;
}
.ql-font-helvetica {
  font-family: "Helvetica", sans-serif;
}
.ql-font-lucida {
  font-family: "Lucida Sans", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", sans-serif;
}

/* Add font sizes */
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
  content: "9";
  font-size: 9px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10";
  font-size: 10px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
  content: "11";
  font-size: 11px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12";
  font-size: 12px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14";
  font-size: 14px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16";
  font-size: 16px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18";
  font-size: 18px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20";
  font-size: 20px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
  content: "22";
  font-size: 22px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24";
  font-size: 24px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26";
  font-size: 26px !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "28";
  font-size: 28px !important;
}
/* Basic Styles */
.container-fluid {
  padding: 15px;
}

.dashboard {
  background-color: #f8f9fa;
}

h1,
h2 {
  font-weight: bold;
  color: #333;
}

button {
  margin: 5px 0;
}

form-group {
  margin-bottom: 1rem;
}

/* Custom Toolbar */
#toolbar {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
}

#toolbar select,
#toolbar button {
  margin-right: 5px;
  margin-bottom: 5px;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .col-md-3,
  .col-md-9 {
    padding: 10px;
  }
}

@media (max-width: 992px) {
  .container-fluid {
    padding: 10px;
  }

  .dashboard {
    padding: 10px;
  }

  h1 {
    font-size: 1.75rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  #toolbar {
    flex-direction: column;
  }

  #toolbar select,
  #toolbar button {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .col-md-3,
  .col-md-9 {
    width: 100%;
    padding: 0;
  }

  .col-md-3 {
    margin-bottom: 15px;
  }

  .form-group {
    margin-bottom: 0.75rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.25rem;
  }
}

@media (max-width: 576px) {
  .container-fluid {
    padding: 5px;
  }

  .dashboard {
    padding: 5px;
  }

  h1 {
    font-size: 1.25rem;
  }

  h2 {
    font-size: 1rem;
  }

  #toolbar select,
  #toolbar button {
    width: 100%;
    margin-bottom: 5px;
  }

  button {
    width: 100%;
  }

  .btn {
    padding: 10px;
    font-size: 0.875rem;
  }
}
