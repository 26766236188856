/* Center the search input on large screens */
.navbar-center {
  display: flex;
  justify-content: center;
  width: 100%;
}

/* Ensure navbar items align to the right */
.navbar-nav {
  margin-left: auto;
}

/* Ensure the brand logo is on the left */
.navbar-brand {
  margin-right: auto;
}

/* Add margin between navbar items */
.navbar-nav .nav-item {
  margin-left: 10px;
}

/* Vertically align nav links and dropdown items */
.navbar-nav .nav-link,
.navbar-nav .dropdown-item {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif; /* Set desired font family */
  font-size: 16px; /* Set desired font size */
  color: #333; /* Set desired font color */
  text-decoration: none; /* Remove underline */
}

/* Special styling for Cart to match other links */
.navbar-nav .nav-link.cart-link {
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  text-decoration: none;
}

/* Active and hover states for nav links and dropdown items */
.navbar-nav .nav-link:hover,
.navbar-nav .dropdown-item:hover,
.navbar-nav .nav-link.active,
.navbar-nav .dropdown-item.active {
  color: #000000; /* Set desired hover and active color */
}

/* Ensure dropdown menu aligns correctly */
.navbar-nav .dropdown-menu {
  left: auto;
  right: 0;
  text-align: center; /* Center align text in dropdown */
}

/* Center align the dropdown items */
.navbar-nav .dropdown-menu .dropdown-item {
  display: flex;
  justify-content: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-center {
    justify-content: flex-start;
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .navbar-nav .nav-item {
    margin-left: 0;
    width: 100%;
    text-align: center;
  }

  .navbar-nav .nav-link {
    justify-content: center;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
}

/* Styles for hamburger menu */
.navbar-toggler {
  border: none;
  padding: 0.25rem 0.75rem;
  margin-left: auto;
}

.navbar-toggler-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-size: contain;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar-collapse {
    display: none; /* Initially hide the collapse menu */
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    display: block; /* Show the collapse menu when toggler is clicked */
  }

  .navbar-center {
    justify-content: flex-start;
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .navbar-nav .nav-item {
    margin-left: 0;
    width: 100%;
    text-align: center;
  }

  .navbar-nav .nav-link {
    justify-content: center;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-center {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-container input[type="text"] {
  margin-right: 0.5rem; /* Space between input and button */
}

@media (max-width: 768px) {
  .navbar-collapse {
    display: none; /* Initially hide the collapse menu */
  }

  .navbar-toggler.collapsed ~ .navbar-collapse {
    display: block; /* Show the collapse menu when toggler is clicked */
  }

  .navbar-center {
    justify-content: center;
    width: 100%;
  }

  .navbar-nav {
    flex-direction: column;
    width: 100%;
  }

  .navbar-nav .nav-item {
    margin-left: 0;
    width: 100%;
    text-align: center;
  }

  .navbar-nav .nav-link {
    justify-content: center;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
}
