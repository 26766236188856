@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Gentium+Book+Plus:ital,wght@0,400;0,700;1,400;1,700&family=Playwrite+NG+Modern:wght@100..400&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Navbar CSS */
.navbar {
  font-family: "Poppins", sans-serif;
  font-size: 17px;
  line-height: 26px;
  text-transform: uppercase;
  box-shadow: 0 8px 6px -6px gray;
  -webkit-box-shadow: 0 8px 6px -6px gray;
  border-bottom: 0px solid gray !important;
}

.nav-link {
  font-weight: 300 !important;
}

.nav-link.active-home,
.nav-link.active-category,
.nav-link.active-cart,
.nav-link.active-dropdown,
.nav-link.active-dashboard {
  border-bottom: 2px solid black;
}

.nav-link.active-home:hover,
.nav-link.active-category:hover,
.nav-link.active-cart:hover,
.nav-link.active-dropdown:hover,
.nav-link.active-dashboard:hover {
  text-decoration: none; /* Remove underline on hover for active links */
  color: red;
}

/* Shared hover styles */
.nav-link:hover:not(.active-home):not(.active-category):not(.active-cart):not(
    .active-dropdown
  ):not(.active-dashboard),
.dropdown-item:hover {
  text-decoration: underline;
  color: blue; /* Example hover effect for nav links and dropdown items */
}

.navbar-brand {
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  letter-spacing: 3px;
}

.navbar-icon {
  position: relative;
  top: -6.5px;
  left: 2px;
}

/* Add this to ensure the items are centered */
.navbar-nav {
  align-items: center;
}

.logout-button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  font-weight: 300 !important;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.logout-button:hover {
  border-bottom: 2px solid black;
}

/* Additional responsive styling for the navbar */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start; /* Align items to the start (left) */
    background-color: black; /* Set background color to black */
    padding: 10px 20px; /* Add padding for better spacing */
  }
  .navbar .navbar-brand,
  .navbar-nav {
    text-align: left;
    width: 100%;
  }
  .navbar .navbar-brand {
    color: white; /* Make the brand text color white for better contrast */
    margin-bottom: 10px; /* Add margin to separate from the hamburger menu */
  }
  .navbar .navbar-toggler {
    align-self: flex-end; /* Align the hamburger menu to the left */
  }
  .navbar .navbar-nav .nav-item {
    margin-bottom: 10px;
  }
  .navbar .navbar-nav .nav-link {
    display: inline-block;
    width: auto;
    padding: 10px 20px;
  }
}

@media (min-width: 769px) {
  .navbar {
    flex-direction: row;
    justify-content: space-between;
  }
}

/* Footer CSS */
.footer {
  font-family: "Gentium Book Plus", sans-serif;
  font-size: 12px;
  color: white;
  line-height: 20px;
  padding: 25px;
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #434343,
    #000000
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #434343, #000000);
}

.footer .text-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping for very small screens */
  gap: 10px; /* Adjust spacing between links */
  font-family: "Gentium Book Plus", sans-serif;
  font-size: 20px;
  color: white;
  line-height: 26px;
}

.footer .text-center1 {
  font-family: "Gentium Book Plus", sans-serif;
  font-size: 12px;
  color: white;
  line-height: 26px;
  text-align: center;
}

.footer a {
  text-decoration: none;
  color: white;
  padding: 10px;
}

.footer a:hover {
  color: #ffefba;
  border-bottom: 1px solid #ffefba;
}

.footer .separator {
  padding: 0 10px; /* Adjust padding for separators */
}

/* Media Queries for small and medium screens */
@media (max-width: 768px) {
  .footer .text-center {
    font-size: 16px; /* Reduce font size */
    gap: 5px; /* Reduce gap between links */
  }

  .footer a {
    padding: 5px; /* Reduce padding */
  }

  .footer .separator {
    padding: 0 5px; /* Adjust padding for separators */
  }
}

@media (max-width: 576px) {
  .footer .text-center {
    font-size: 14px; /* Further reduce font size */
    gap: 3px; /* Further reduce gap between links */
  }

  .footer a {
    padding: 3px; /* Further reduce padding */
  }

  .footer .separator {
    padding: 0 3px; /* Adjust padding for separators */
  }
}

@media (max-width: 768px) {
  .footer .badges img {
    width: 80px; /* Set a fixed width */
    height: auto; /* Maintain aspect ratio */
  }
}

@media (max-width: 576px) {
  .footer .badges img {
    width: 60px; /* Set a smaller fixed width */
    height: auto; /* Maintain aspect ratio */
  }
}

/* Page Not Found CSS */
.pnf {
  display: flex;
  min-height: 65vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pnf-title {
  font-size: 100px;
  font-weight: 700;
}

.pnf-heading {
  font-weight: normal;
}

.pnf-btn {
  color: black;
  border: 1px solid black;
  text-decoration: none;
  padding: 10px;
  margin-top: 10px;
}

.pnf-btn:hover {
  background-color: black;
  color: white;
}

/* Contact Page CSS */
.contactus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.contactus img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.contactus h2 {
  font-size: 24px;
  background-color: #000; /* Background color for the heading */
  color: #fff; /* Text color for the heading */
  padding: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.contactus p {
  margin: 10px 0;
  text-align: justify;
  font-size: 16px;
}

.contactus .contact-info p {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 16px;
}

.contactus .contact-info p svg {
  margin-right: 8px;
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .contactus {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .contactus img {
    width: 50%;
    margin-bottom: 0;
  }

  .contactus .contact-info {
    width: 45%;
  }

  .contactus p {
    text-align: left;
  }
}

@media (min-width: 1024px) {
  .contactus img {
    width: 40%;
  }

  .contactus .contact-info {
    width: 50%;
  }
}

/* About Page CSS */
.aboutus {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: auto;
}

.aboutus img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.montserrat-font {
  font-family: "Montserrat", sans-serif;
}

.aboutus h2 {
  font-size: 24px;
  padding: 10px;
}

.aboutus p {
  margin: 10px 0;
  text-align: center;
}

@media (min-width: 768px) {
  .aboutus {
    flex-direction: row;
    height: 70vh;
  }

  .aboutus .col-md-6,
  .aboutus .col-md-4 {
    flex: 1;
    text-align: left;
  }

  .aboutus img {
    height: 320px;
    width: 90%;
    margin: 0;
  }

  .aboutus h2 {
    font-size: 28px;
  }

  .aboutus p {
    text-align: justify;
  }
}

@media (min-width: 1024px) {
  .aboutus {
    height: 60vh;
  }

  .aboutus img {
    height: auto;
  }
}

/* Privacy Policy Page CSS */
.policy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  height: auto;
}

.policy img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.policy p {
  margin: 10px 0;
  text-align: center;
}

@media (min-width: 768px) {
  .policy {
    flex-direction: row;
  }

  .policy .col-md-6 {
    flex: 1;
    padding: 20px;
  }

  .policy .policy-image {
    width: 70%;
    height: auto;
  }

  .policy p {
    text-align: justify;
  }

  .policy ul {
    padding-left: 20px;
  }
}

@media (min-width: 1024px) {
  .policy {
    height: 60vh;
  }

  .policy .col-md-6 {
    padding: 40px;
  }

  .policy .policy-image {
    width: 60%;
  }
}

/* Specific dropdown item styling */
.dropdown-item {
  text-transform: uppercase;
}

.text-center h4 {
  color: black; /* Or any contrasting color */
}

/* ==================== */
.product-link {
  text-decoration: none !important;
  color: black !important;
}
