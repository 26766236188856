@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* Container Styles */
.container {
  margin-top: 20px; /* Adjust margin as needed */
  text-align: center;
}

/* Title Container Styles */
.title-container {
  text-align: center;
  margin-bottom: 20px; /* Adjust spacing between title and buttons */
}

/* Title Styles */
.category-title {
  font-family: "Montserrat", sans-serif;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}

/* Row Styles */
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Column Styles */
.col-md-4 {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

/* Card Styles */
.card {
  border: none;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 100%;
  max-width: 300px;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Category Button Styles */
.button-54 {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px,
    5px 5px 0px 0px;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  transition: transform 0.3s, background 0.3s;
  width: 100%;
}

.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

.button-54:hover {
  background: linear-gradient(45deg, #fffbf5, #bcfefb);
}

/* Responsive Styles */
@media (min-width: 768px) {
  .button-54 {
    padding: 0.5em 1em;
    font-size: 14px;
    width: auto;
  }
  .category-title {
    font-size: 28px;
  }
}

@media (max-width: 768px) {
  .col-md-4 {
    width: 33.33%;
    margin: 10px 0;
  }
  .button-54 {
    font-size: 10px;
    padding: 0.25em 0.5em;
    width: auto;
  }
  .category-title {
    font-size: 24px;
    margin-top: 50px;
  }
}

@media (max-width: 576px) {
  .col-md-4 {
    width: 100%;
    margin: 10px 0;
  }
  .button-54 {
    font-size: 12px;
    padding: 0.25em 0.5em;
    width: auto;
  }
  .category-title {
    font-size: 20px;
    margin-top: 40px;
  }
}
