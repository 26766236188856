/* styles/Footer.css */

.footer {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  border-top: 1px solid #e7e7e7;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer h1 {
  font-size: 18px;
  color: #333;
  margin: 0;
}

.footer p {
  margin: 10px 0;
  color: #555;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 5px;
}

.footer a:hover {
  text-decoration: underline;
}

.badges {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.badges img {
  margin: 10px;
}

@media (max-width: 768px) {
  .footer h1 {
    font-size: 16px;
  }

  .footer p {
    font-size: 14px;
  }

  .badges img {
    width: 120px;
    height: auto;
  }
}
.badges img {
  max-width: 100%;
  max-height: 50px;
  width: auto; /* Adjust this value as needed */
  height: auto;
}

@media (max-width: 480px) {
  .footer h1 {
    font-size: 14px;
  }

  .footer p {
    font-size: 12px;
  }

  .badges img {
    width: 100px;
    height: auto;
  }
}

/* Additional CSS for NavLink */
.footer .navlink {
  color: #ffffff;
  text-decoration: none;
}

.footer .navlink:hover {
  text-decoration: none;
}
