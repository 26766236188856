/* Import the font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Libre+Franklin:ital,wght@0,100..900;1,100..900&display=swap");

body {
  font-family: "Libre Franklin", sans-serif;
}

.category {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px !important;
}

.category h4 {
  font-family: "Libre Franklin", sans-serif;
  font-weight: 700;
}

.category .container {
  width: 100%;
  padding: 0 15px;
}

.category .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.category .card {
  background-color: rgba(128, 128, 128, 0.097);
  flex: 1 1 calc(33.333% - 20px); /* Ensures three cards per row with spacing */
  margin: 10px;
  max-width: calc(
    33.333% - 20px
  ); /* Ensures three cards per row with spacing */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}

@media (max-width: 992px) {
  .category .card {
    flex: 1 1 calc(50% - 20px); /* Two cards per row on medium screens */
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .category .card {
    flex: 1 1 calc(50% - 20px); /* Two cards per row on small screens */
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 576px) {
  .category .card {
    flex: 1 1 calc(100% - 20px); /* One card per row on extra small screens */
    max-width: calc(100% - 20px);
  }
}

.category .card-name-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.category .card-price {
  color: green;
  font-weight: bold;
}

.category button {
  border-radius: 0;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

.category .card-text {
  color: rgb(90, 89, 89);
}

.category .card img:hover {
  transform: scale(0.9);
}

.category .card-img-top {
  height: 300px !important;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Ensure card layout adjusts appropriately */
.card {
  flex: 1 1 auto;
  margin: 30px;
  max-width: 400px; /* Increased width */
  min-width: 300px; /* Ensures cards have a minimum width */
}

.card-body {
  display: flex;
  flex-direction: column;
}

.card-name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card-title {
  font-size: 1.1rem;
}

.card-price {
  font-size: 1.1rem;
}

.category .row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
