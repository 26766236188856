@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Gentium+Book+Plus:ital,wght@0,400;0,700;1,400;1,700&family=Playwrite+NG+Modern:wght@100..400&display=swap");

/* Container styles */
.container {
  padding: 15px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Blog title styles */
.blog-title {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
}

/* Blog content styles */
.blog-content {
  text-align: left;
  font-size: 1.2em;
  line-height: 1.6;
}

/* Spinner styles */
.spinner-border {
  width: 3rem;
  height: 3rem;
  margin-bottom: 15px;
}

/* Loading text styles */
.loading-text {
  font-size: 1.2em;
}

/* Media queries for responsiveness */
@media (max-width: 1200px) {
  .container {
    padding: 10px;
  }

  .blog-title {
    font-size: 2em;
  }

  .blog-content {
    font-size: 1.1em;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 5px;
  }

  .blog-title {
    font-size: 1.75em;
  }

  .blog-content {
    font-size: 1em;
  }
}

@media (max-width: 576px) {
  .blog-title {
    font-size: 1.5em;
  }

  .blog-content {
    font-size: 0.9em;
  }
}
