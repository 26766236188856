/* Overall page layout adjustments */
.cart-page {
  margin-top: 64px;
}

/* Styling for the main heading */
.cart-page h1 {
  padding: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  background-color: rgba(0, 0, 255, 0.1);
  text-align: center;
  border-radius: 8px;
}

/* Styling for the subtitle */
.cart-page h1 > p {
  font-size: 18px;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
}

/* Styling for each item card */
.cart-page .card {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
}

/* Styling for the remove button container */
.cart-page .cart-remove-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styling for the cart summary section */
.cart-summary {
  text-align: center;
  margin-top: 30px;
}

/* Styling for the heading of cart summary */
.cart-summary h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

/* Styling for the total price */
.cart-summary h4 {
  font-size: 20px;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
}

/* Styling for the current address section */
.current-address {
  margin-top: 30px;
  text-align: center;
}

/* Styling for the heading of current address */
.current-address h4 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
}

/* Styling for the address text */
.current-address h5 {
  font-size: 16px;
  margin-bottom: 15px;
  font-family: "Roboto", sans-serif;
}
