@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap");

/* General CSS */

.banner-img {
  margin-top: 0px;
}

/* Banner Image */
.banner-image {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden; /* Optional */
}

.banner-image img {
  width: 100%;
  height: 60%; /* Fixed height */
  object-fit: fill; /* Stretch the image to fit the container */
}

body {
  background: linear-gradient(
    to right,
    #f0f8ff,
    #ebebf1
  ); /* Adjust the colors as needed */
  margin: 0;
  padding: 0;
}

.home-page h1 {
  color: gray;
  font-family: "Playfair Display", serif;
}

.home-page {
  display: flex;
  flex-wrap: wrap;
}

.home-page .card {
  background-color: rgba(128, 128, 128, 0.097);
  width: 18rem;
}

.home-page .card-name-price {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.home-page .card-price {
  color: green;
  font-weight: bold;
}

.home-page button {
  border-radius: 0;
  width: 100%;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

.home-page .card-text {
  color: rgb(90, 89, 89);
}

.home-page .card img:hover {
  transform: scale(0.9);
}

.home-page .card-img-top {
  height: 300px !important;
}

/* ==================
filters
============== */
.filters h4 {
  margin-top: 100px;
  color: gray !important;
  border-bottom: 1px solid rgba(128, 128, 128, 0.337);
}

.ant-checkbox-wrapper {
  margin-bottom: 5px !important;
  margin-left: 1px;
}

.ant-radio-wrapper {
  margin-bottom: 5px !important;
  margin-left: 10px;
}

.filters button {
  background-color: black;
  width: 100%;
  border: none;
}

.filters button:hover {
  background-color: white;
  color: black;
  transition: 0.5s ease;
}

/* ==================
Category Buttons
============== */
.category-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin: 20px 0;
}

.category-buttons .btn {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: all 0.3s ease;
  text-transform: uppercase;
}

.category-buttons .btn-primary {
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff;
}

.category-buttons .btn-primary:hover {
  background-color: #0056b3;
  color: #fff;
  border-color: #0056b3;
}

@media (max-width: 768px) {
  .category-buttons .btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 576px) {
  .category-buttons .btn {
    font-size: 12px;
    padding: 6px 12px;
  }
}

/* ==================
Why Us Section
============== */
.why-us {
  text-align: center;
  margin: 40px 0;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.why-us h2 {
  font-size: 28px;
  margin-bottom: 20px;
  font-family: "Playfair Display", serif; /* Updated font */
  color: #333; /* Optional: change the color */
}

.why-us p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: left;
  font-family: "Poppins", sans-serif; /* Updated font */
  color: #555; /* Optional: change the color */
}

.why-us-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.why-us-text {
  flex: 1;
  min-width: 300px;
}

.why-us-image {
  flex: 1;
  min-width: 300px;
  display: flex;
  justify-content: flex-end; /* Move image to the right */
  align-items: center;
  padding-right: 20px; /* Adjust the padding/margin as needed */
}

.why-us-image img {
  max-width: 100%;
  width: auto;
  height: auto;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .why-us-image {
    justify-content: center; /* Center image on medium devices */
    padding-right: 0; /* Remove right padding */
  }

  .why-us-container {
    flex-direction: column; /* Stack elements vertically */
  }

  .why-us-image img {
    max-height: 200px; /* Adjust height for medium devices */
  }
}

@media (max-width: 576px) {
  .why-us-image {
    justify-content: center; /* Center image on small devices */
    padding-right: 0; /* Remove right padding */
  }

  .why-us-image img {
    max-height: 150px; /* Adjust height for small devices */
  }
}

/* Animation */
.anim-fade-in {
  display: block;
  margin: 0 auto;
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Up-down Animation */
@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.anim-up-down {
  animation: upDown 2s infinite;
}

/* ==================
Testimonials Section
============== */
.testimonials-section {
  margin: 40px 0;
  text-align: center;
}

.testimonials {
  display: flex;
  justify-content: flex-start; /* Align items to start from the left */
  gap: 40px; /* Add gap between testimonials */
  flex-wrap: wrap;
}

.testimonial {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  flex: 1; /* Ensure testimonials take available space */
  margin-bottom: 20px; /* Add margin at the bottom for vertical spacing */
}

.testimonial p {
  margin: 0 0 10px;
}

.testimonial strong {
  display: block;
  margin-top: 10px;
}

/* Responsive adjustments for testimonials */
@media (max-width: 992px) {
  .testimonials {
    flex-direction: column;
    align-items: center;
  }

  .testimonials .testimonial {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .testimonials {
    flex-direction: column;
    align-items: center;
  }

  .testimonials .testimonial {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .testimonials {
    flex-direction: column;
    align-items: center;
  }

  .testimonials .testimonial {
    font-size: 12px;
  }
}

/* Loading Spinner */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100px; /* Adjust as needed */
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px; /* Space between spinner and text */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loading-text {
  font-size: 16px;
  color: #555;
}
