/* Update Product Page Styles */
.dashboard {
  padding: 20px;
  background: linear-gradient(to right, #ffecd2, #fcb69f);
}

.admin-menu {
  background-color: #ffe3e3;
  padding: 10px;
  border-radius: 5px;
  position: fixed; /* Fix the admin menu to the top */
  top: 0;
  width: 100%;
  z-index: 1000;
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  height: 90%;
  width: 18rem;
  margin: 10px;
}

.card h3 {
  color: #333333;
  font-family: "Arial", sans-serif;
  margin-bottom: 10px;
}

.icon {
  color: #f77a52;
  margin-right: 10px;
}

.container-fluid {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px; /* To account for fixed admin menu */
}

.col-md-3,
.col-md-9 {
  padding: 10px;
}

@media (max-width: 768px) {
  .col-md-3,
  .col-md-9 {
    padding: 5px;
  }

  .card {
    width: 100%; /* Full width on smaller screens */
  }
}

.form-select,
.form-control,
.btn {
  margin-bottom: 15px;
}
